<template>
  <div class="logo-grid">
    <BaseTitle
      v-if="!!title"
      level="h2"
    >
      {{ title }}
    </BaseTitle>
    <div
      class="grid justify-items-center gap-0 lg:gap-8 py-0 mt-8"
      :class="[
        computedCols,
        computedLogos > 2 ? 'xl:py-28' : 'xl:py-16'
      ]"
    >
      <NuxtImg
        v-for="(logo, idx) in logos"
        :key="idx"
        :src="logo.src"
        :alt="logo.alt || 'generic logo'"
        data-aos="zoom-in"
        :data-aos-delay="idx * 100"
        quality="80"
        loading="lazy"
        placeholder
        class="p-4 aspect-3/2 flex-shrink-0 object-cover max-w-[50vw] h-auto"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseTitle from '../BaseTitle.vue';

export interface BaseLogoGridProps {
  title?: string
  logos: {src: string, alt: string }[]
  cols?: number | string
}
// get logos from props to build the grid
const props = withDefaults(defineProps<BaseLogoGridProps>(), {
  title: undefined,
  cols: 4,
});

const computedCols = computed(() => {
  return `grid-cols-1 md:grid-cols-2 lg:grid-cols-${props?.cols || '2'}`;
});

const computedLogos = computed(() => {
  return props.logos.length || 0;
});
</script>

<style scoped>
@media (min-width: 1024px) {
  .lg\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
</style>
