<template>
  <BaseSection>
    <BaseLogoGrid
      :title="data.title"
      :logos="computedLogos"
      :cols="data.columns"
    />
  </BaseSection>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseSection from '../BaseSection.vue';
import BaseLogoGrid, { type BaseLogoGridProps } from '../grids/BaseLogoGrid.vue';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const computedLogos = computed(() => {
  const brandsLogos: BaseLogoGridProps['logos'] = props.data.logos.map((item: Record<string, any>) => {
    const logo = item.logo || {};
    const imageSrc = logo?.original?.src;
    const brandLogo: BaseLogoGridProps['logos'][0] = {
      src: imageSrc,
      alt: logo.title || '',
    };

    return brandLogo;
  });

  return brandsLogos;
});
</script>
